// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");

// Variables
@import "1-variables/app";

// Bootstrap
@import "bootstrap/scss/bootstrap";

// Theme mixins
@import "2-mixins/button";
@import "2-mixins/modal";
@import "2-mixins/switch";
@import "2-mixins/tabs";
@import "2-mixins/wizard";

// Theme components
@import "3-components/accordion";
@import "3-components/alert";
@import "3-components/avatar";
@import "3-components/badge";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/chart";
@import "3-components/daypicker";
@import "3-components/dre";
@import "3-components/dropdown";
@import "3-components/feather";
@import "3-components/footer";
@import "3-components/forms";
@import "3-components/hamburger";
@import "3-components/landing";
@import "3-components/list_group";
@import "3-components/modal";
@import "3-components/navbar";
@import "3-components/reboot";
@import "3-components/progress";
@import "3-components/sidebar";
@import "3-components/sizing";
@import "3-components/tables";
@import "3-components/tabs";
@import "3-components/timeline";
@import "3-components/type";
@import "3-components/wrapper";
@import "3-components/content";
@import "3-components/main";
@import "3-components/settings";
@import "3-components/ie";
@import "3-components/divider";
@import "3-components/pesq_modal";

// Theme utilities
@import "4-utilities/borders";
@import "4-utilities/cursors";
@import "4-utilities/overflow";

// 3rd party plugins
@import "react-quill/dist/quill.bubble";
@import "react-quill/dist/quill.snow";
@import "react-redux-toastr/src/styles/index";
// @import "react-bootstrap-table-next/dist/react-bootstrap-table2.min";
// @import "jvectormap-next/jquery-jvectormap";
// @import "react-dragula/dist/dragula";
// @import "fullcalendar/dist/fullcalendar";

// 3rd party plugin styles
@import "5-vendor/fullcalendar";
@import "5-vendor/perfect-scrollbar";
@import "5-vendor/quill";
@import "5-vendor/react-bootstrap-table";
@import "5-vendor/react-datetime";
@import "5-vendor/react-select";
@import "5-vendor/toastr";
@import "5-vendor/react-dropzone";

body {
  font-size: 0.75rem !important;
}

label {
  margin-bottom: 0.1rem !important;
}

.selection-input-4 {
  margin: 5px !important;
}

@include media-breakpoint-down(md) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.form-group {
  margin-bottom: 0.35rem;
}

.custom-control {
  padding-top: 0.3rem;
}

.custom-control-label {
  padding-top: 0.165em;
}

.custom-control-label::after {
  position: absolute;
  top: 0.3rem;
  left: -1.2rem;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-control-label::before {
  position: absolute;
  top: 0.3rem;
  left: -1.2rem;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: #adb5bd solid 1px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #2690ff;
  background-color: #2690ff;
}

.content {
  padding: 1rem;
}

.card {
  margin-bottom: 7px;
}

.card-body {
  padding: 0.75rem;
}

.form-control {
  font-size: 0.75rem;
}

.label-auth-login,
.input-auth-login {
  color: black;
}

.card-header,
.card-body {
  padding: 0.5rem 0.75rem;
}

[class^="col-"].no-gutters,
[class*=" col-"].no-gutters {
  margin-right: 0;
  margin-left: 0;

  padding-right: 0;
  padding-left: 0;
}

.table-record-count {
  text-transform: uppercase;
  font-weight: bold;
  color: #368b91;
  margin-left: 0.25rem;
}

.clickable-div {
  border: 1px solid #ddd;
  border-radius: 5px;
  min-height: 60px;
  max-height: 100px;
  padding: 8px 4px;
}

.clickable-div:hover {
  background-color: #eee;
  cursor: pointer;
}

.clickable-div.active {
  border: none;
  background-color: #d5e7ff;
  cursor: default !important;
}

// Recortes
.img-sel-recorte {
  width: 100px;
  height: auto;
  padding: 7px 14px;

  &.active {
    border: 2.5px solid #eee;
  }

  &.selectable {
    cursor: pointer;
  }

  &.selectable:hover {
    border: 2.5px solid #eee;
  }
}

.react-select__menu {
  z-index: 30 !important;
}
